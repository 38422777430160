import { Ref, computed, inject, provide, ref } from "@nuxtjs/composition-api";
import { eAnnualProductRegionGroup, getCountryItems } from "@loadsure/core/";
import { iItem, notEmpty } from "@loadsure/utils";

export const CHRISTMAS = 12;
export const HALLOWEEN = 10;

export function useSiteConfigurationProvide(context) {
  const {
    $axios,
    $config: { apiUrl },
    $sentry
  } = context;

  const shortName: Ref<string> = ref("");
  const name: Ref<string> = ref("");
  const isWhitelabel: Ref<boolean> = ref(false);
  const logos: Ref<any> = ref({});
  const issueMasterPolicies: Ref<boolean> = ref(false);
  const partnerCountries: Ref<string[]> = ref([]);
  const internetTradingPolicy: Ref<any> = ref("");
  const allowQuebecPickUp: Ref<boolean> = ref(false);
  const allowOceanCargoProduct: Ref<boolean> = ref(false);
  const defaultCoverageId: Ref<string> = ref("loadsure");
  const annualProductsRegion: Ref<string[]> = ref([]);
  const portalConfigCurrencies: Ref<string[]> = ref([]);
  const assuredCountries: Ref<string[]> = ref([]);

  async function loadConfiguration() {
    try {
      const configuration = await $axios.$get(`${apiUrl}/api/portalConfig`);
      if (configuration) {
        shortName.value = configuration.shortName;
        name.value = configuration.name;
        isWhitelabel.value = configuration.isWhitelabel;
        logos.value = configuration.logos;
        partnerCountries.value = configuration.countries.partnerCountries;
        internetTradingPolicy.value = configuration.legal?.internetTradingPolicy;
        allowQuebecPickUp.value = configuration.allowQuebecPickUp;
        allowOceanCargoProduct.value = configuration.allowOceanCargoProduct;
        annualProductsRegion.value = configuration.annualProductsRegion;
        defaultCoverageId.value = configuration.defaultCoverageId;
        portalConfigCurrencies.value = configuration.countries.portalCurrencies;
        assuredCountries.value = configuration.countries.assuredCountries;
      }
    } catch (error) {
      $sentry?.captureException(`Error loading configuration: ${error}`);
    }
  }

  loadConfiguration();

  function getLogo(type: string) {
    return logos.value?.[type];
  }

  const partnerCountryItems = computed(() =>
    getCountryItems(partnerCountries.value)
  );

  provide("shortName", shortName);
  provide("name", name);
  provide("isWhitelabel", isWhitelabel);
  provide("getLogo", getLogo);
  provide("partnerCountries", partnerCountries);
  provide("internetTradingPolicy", internetTradingPolicy);
  provide("allowQuebecPickUp", allowQuebecPickUp);
  provide("allowOceanCargoProduct", allowOceanCargoProduct);
  provide("annualProductsRegion", annualProductsRegion);
  provide("defaultCoverageId", defaultCoverageId);
  provide("partnerCountryItems", partnerCountryItems);
  provide("portalConfigCurrencies", portalConfigCurrencies);
  provide("issueMasterPolicies", issueMasterPolicies);
  provide("loadConfiguration", loadConfiguration);
  provide("assuredCountries", assuredCountries);

  return { loadConfiguration, internetTradingPolicy };
}

export function useSiteConfigurationInject() {
  const shortName = inject("shortName", {}) as Ref<string>;
  const name = inject("name", {}) as Ref<string>;
  const isWhitelabel = inject("isWhitelabel", {}) as Ref<boolean>;
  const allowQuebecPickUp = inject("allowQuebecPickUp", {}) as Ref<boolean>;
  const issueMasterPolicies = inject("issueMasterPolicies", {}) as Ref<boolean>;
  const allowOceanCargoProduct = inject(
    "allowOceanCargoProduct",
    {}
  ) as Ref<boolean>;
  const annualProductsRegion = inject("annualProductsRegion", {}) as Ref<
    string[]
  >;
  const defaultCoverageId = inject("defaultCoverageId", {}) as Ref<
  string
>;
  const getLogo = inject("getLogo", {}) as (type: string) => any;
  const partnerCountries = inject("partnerCountries", {}) as Ref<string[]>;
  const portalConfigCurrencies = inject("portalConfigCurrencies", {}) as Ref<
    string[]
  >;
  const internetTradingPolicy = inject("internetTradingPolicy", {}) as Ref<any>;
  const partnerCountryItems = inject("partnerCountryItems", {}) as Ref<
    iItem<string>[]
  >;

  const assuredCountries = inject("assuredCountries", {}) as Ref<string[]>;
  const loadConfiguration = inject(
    "loadConfiguration",
    {}
  ) as () => Promise<void>;

  const isAnnualProductEuropeanRegion = computed(() => {
    const productRegion =
      Array.isArray(annualProductsRegion.value) &&
      notEmpty(annualProductsRegion.value)
        ? annualProductsRegion.value[0]
        : undefined;

    return productRegion === eAnnualProductRegionGroup.EUROPE;
  });

  const isEuropeRatingEngine = computed(() => defaultCoverageId.value === "loadsure_europe_rate_card");


  return {
    shortName,
    name,
    isWhitelabel,
    getLogo,
    partnerCountries,
    internetTradingPolicy,
    partnerCountryItems,
    allowQuebecPickUp,
    allowOceanCargoProduct,
    annualProductsRegion,
    isEuropeRatingEngine,
    isAnnualProductEuropeanRegion,
    portalConfigCurrencies,
    issueMasterPolicies,
    loadConfiguration,
    assuredCountries
  };
}

export function isActiveSeason(month) {
  const date = new Date();
  const currentMonth = date.getMonth() + 1;
  return currentMonth === month;
}
