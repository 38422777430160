import { render, staticRenderFns } from "./portal.vue?vue&type=template&id=8a0146d8&scoped=true&lang=pug"
import script from "./portal.vue?vue&type=script&lang=ts"
export * from "./portal.vue?vue&type=script&lang=ts"
import style0 from "./portal.vue?vue&type=style&index=0&id=8a0146d8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a0146d8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomizableOverlay: require('/workspace/portal/hosting/components/CustomizableOverlay.vue').default,TheTopNavigation: require('/workspace/portal/hosting/components/TheTopNavigation.vue').default,LsAlert: require('/workspace/portal/hosting/components/_base/Alert/Alert.vue').default,Loading: require('/workspace/portal/hosting/components/Loading/Loading.vue').default,NavigationDrawerV2: require('/workspace/portal/hosting/components/NavigationDrawerV2/NavigationDrawerV2.vue').default,LsBanner: require('/workspace/portal/hosting/components/_base/Banner/Banner.vue').default,LsCardError: require('/workspace/portal/hosting/components/_base/Card/CardError.vue').default,LsInfoBox: require('/workspace/portal/hosting/components/_base/InfoBox/InfoBox.vue').default,LsInputGeneric: require('/workspace/portal/hosting/components/_base/Input/InputGeneric/InputGeneric.vue').default,LsDialogGeneric: require('/workspace/portal/hosting/components/_base/Dialog/DialogGeneric/DialogGeneric.vue').default,InternetTradingPolicyPopUp: require('/workspace/portal/hosting/components/InternetTradingPolicyPopUp.vue').default,Splash: require('/workspace/portal/hosting/components/Splash.vue').default,SignIn: require('/workspace/portal/hosting/components/signIn/SignIn.vue').default,SignInWithEmail: require('/workspace/portal/hosting/components/signIn/SignInWithEmail.vue').default})
